import Vue from 'vue'
import axios from 'axios'
import moment from 'moment-timezone'

const initState = {
  actions: {
    sendingOptions: {
      label: 'Sending options',
      isOpen: true,
      actions: [
        {
          name: 'Send SMS', action: 'Notification', description: 'Send an sms', icon: 'uil uil-envelope',
          options: {
            type: 'EmailAndSms',
            source: 'Redemption',
            email: null,
            phone: null,
            content: null,
          }
        },
        {
          name: 'Send Email', action: 'SendEmail', description: 'Send an email', icon: 'uil uil-envelope',
          options: {
            integration_user: null,
          }
        },
        {
          name: 'Redemption', action: 'Redemption', description: 'Send an redemption', icon: 'uil uil-usd-square',
          options: {
            content: null,
            reminders: [],
            day_to_expire: 14,
            active: false,
            send_sms: true,
            send_email: false,
            content_email: null,
          }
        },
        {
          name: 'Alert', action: 'Alert', description: 'Send an alert', icon: 'uil uil-shield-exclamation',
          options: {
            users: [],
            content: "[phone_number] opted into the campaign with the keyword [keyword]"
          }
        },

      ]
    },
    conditionsAndWorkflow: {
      label: 'Conditions and workflow',
      isOpen: false,
      actions: [
        {
          name: 'Wait', action: 'Wait', description: 'Wait for a certain period of time, or until conditions are matched.', icon: 'uil uil-clock',
          options: { key: 'wait_for', duration: 1, period: 'day(s)', timeout: true, action_type: 1 },
          segments: {
            logic: 'And',
            data: []
          },
        },
        // { 
        //   name: 'If/Else', action: 'IfElse', description: 'Continue the automation in a different way depending on whether the conditions are matched.', icon: 'uil uil-question-circle',
        //   options: {
        //     condition: 'SMS',
        //     operator: 'Is',
        //     statement: null,
        //     statements: [],
        //   }
        // },
        { name: 'Goal', action: 'Goal', description: 'Goal', icon: 'uil uil-medal', options: { name: null, type: 'Wait for', duration: 1, period: 'day(s)' } },
        {
          name: 'Condition', action: 'Condition', description: 'Condition', icon: 'uil uil-question-circle',
          branches: [
            {
              name: 'None',
              none: true,
              // duration: 1,
              // period: 'hour',
            }
          ],
          options: {
            invalid_responder_message: '',
          }
        },
      ]
    },
    contacts: {
      label: 'Contacts',
      isOpen: false,
      actions: [
        {
          name: 'Collect contact data', action: 'CollectContactData', description: 'Send sms to collect contact data', icon: 'uil uil-user-exclamation',
          collectFields: [
            { name: 'first_name', label: 'Contact first name', enable: true, message: 'Please reply with your Contact first name' },
            { name: 'last_name', label: 'Contact last name', enable: true, message: 'Please reply with your Contact last name' },
            { name: 'email', label: 'Contact email', enable: true, message: 'Please reply with your Contact email' },
            { name: 'birthday', label: 'Birthday', enable: true, message: 'When is your birthday? Please reply with mm/dd', format: 'mm/dd' },
            { name: 'anniversary', label: 'Anniversary', enable: false, message: 'When is your anniversary? Please reply with mm/dd', format: 'mm/dd' },
            { name: 'business_name', label: 'Business Name', enable: false, message: 'What is your Business Name?' },
            { name: 'website_url', label: 'Website URL', enable: false, message: 'What is your Website URL?' },
          ],
        },
        {
          name: 'Subscribe contact to List', action: 'SubscribeContactToList', description: 'Subscribe contact to list', icon: 'uil uil-user-plus',
          options: {
            campaigns: [],
            blockIndex: 0,
          },
        },
      ]
    }
  },
  automations: null,
  total: 0,
  queries: {
    per_page: 10,
    page: 1,
    status: 'active',
    search: null,
    sort_by: 'id',
    sort_desc: false,
    keywords: [],
  }
}

export default {
  namespaced: true,
  state: Vue.util.extend({}, initState),

  mutations: {
    getAllSuccess(state, { automations, total, queries }) {
      state.automations = automations
      state.total = total
      state.queries = queries
    },

    createSuccess(state) {
      state.automations = null
    },

    updateSuccess(state, automation) {
      if (state.automations) {
        state.automations = state.automations.map((obj) =>
          obj.id === automation.id ? automation : obj
        )
      }
    },

    duplicateSuccess(state) {
      state.automations = null
    },

    resetAllSuccess(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    },
  },

  actions: {
    resetAll({ commit }) {
      commit('resetAllSuccess')
    },

    all({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/automations/all')
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getAll({ commit }, { queries }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/automations', { params: queries })
          .then((res) => {
            commit('getAllSuccess', {
              automations: res.data.data,
              total: res.data.meta.total,
              queries: queries,
            })
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    find({ commit }, automationId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/automations/${automationId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    findByUniqueId({ commit }, uniqueId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/automations/${uniqueId}/unique-id`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    duplicate({ commit }, uniqueId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/automations/${uniqueId}/duplicate`)
          .then((res) => {
            commit('duplicateSuccess')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getWaitContacts({ commit }, { automationId, blockId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/automations/${automationId}/wait-contacts/${blockId}`)
          .then((res) => {
            resolve(res.data.data.total_contacts)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    createOrUpdate({ commit }, { automation, reload = true }) {
      return new Promise((resolve, reject) => {
        axios
          .post(automation.id ? `/automations/${automation.id}` : `/automations`, automation)
          .then((res) => {
            if (reload) {
              commit('createSuccess')
            }
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    refresh({ commit }, automationId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`automations/${automationId}/refresh`)
          .then((res) => {
            commit('updateSuccess', res.data.data)
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    destroy({ commit }, automationId) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`automations/${automationId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    clone({ commit }, automationId) {
      return new Promise((resolve, reject) => {
        axios
          .post(`automations/${automationId}/clone`)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => reject(err))
      })
    },

    generateWebhookUrl({ state, commit }, query) {
      return new Promise((resolve, reject) => {
        axios.get(`automations/new-webhook`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },
  },

  getters: {
    actions(state, getters, rootState, rootGetters) {
      let res = state.actions
      let user = rootGetters['auth/user']
      if (user && user.business && user.business.is_active_mms) {
        res.sendingOptions.actions.push({
          name: 'Image', action: 'ImageUpload', description: 'Send an image, Giff or vCard', icon: 'uil uil-image',
          options: {
            images: [],
          }
        });
      }
      return res;
    },

    actionList(state, getters) {
      let actions = getters['actions']
      let res = []
      res = res.concat(actions['conditionsAndWorkflow']['actions'])
        .concat(actions['contacts']['actions'])
        .concat(actions['sendingOptions']['actions'])

      return res;
    },

    all(state) {
      return state.automations
    },

    total(state) {
      return state.total
    },

    queries(state) {
      return state.queries
    },

    conditionCurrentTimeValues() {
      let list = []
      for (let i = 0; i <= 23; i++) {
        let str = (i % 12 === 0 ? 12 : i % 12) + (i > 12 ? 'PM' : 'AM')
        list.push({ label: `${i} ( ${str} )`, value: i })
      }
      return list
    },

    conditionCurrentDayValues() {
      let list = []
      for (let i = 1; i <= 31; i++) {
        list.push({ label: i, value: i })
      }
      return list
    },

    conditionCurrentMonthValues() {
      let list = []
      let months = moment.months()
      for (let i = 1; i <= 12; i++) {
        list.push({ label: months[i-1], value: i })
      }
      return list
    },

    conditionCurrentYearValues() {
      let list = []
      let currentYear = new Date().getFullYear()
      for (let i = currentYear; i <= currentYear + 10; i++) {
        list.push({ label: i, value: i })
      }
      return list
    },

    conditionCurrentDayOfWeekValues() {
      let list = [
        { label: 'Weekdays', value: '-1' },
        { label: 'Weekends', value: '-2' },
      ]

      let days = moment.weekdays()
      for (let i = 1; i <= 7; i++) {
        list.push({ label: days[i % 7], value: i % 7 })
      }
      return list
    },
  },
}
