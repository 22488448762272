import Vue from 'vue'

Vue.filter('couponType', function (value) {
  let res = 'N/A'
  switch (value) {
    case 'landing':
      res = 'Landing Page'
      break;
    case 'loop':
      res = 'Loop Page'
      break;
    case 'vote':
      res = 'Vote Page'
      break;
    case 'popup':
      res = 'Widget'
      break;    
    case 'cms':
      res = 'CMS'
      break;    
    case 'goto':
      res = 'GotoWebinar'
      break;    
    case 'api':
      res = 'NetEngine'
      break;    
  }
  return res
})
